import _get from 'lodash/get';
import _set from 'lodash/set';
import _hasIn from 'lodash/hasIn';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _unset from 'lodash/unset';
import _cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import {
  OptionSystem,
  isSuperOrGov,
  RENTAL_INCOME,
  GOVERNMENT_BENEFIT,
  INVESTMENT,
  UNEMPLOYED,
  isRentalOrInvestment,
  isEligibleForBankConnect,
} from '_config/_constants';
import { config } from '_config';
import { idTypeAUDriverLicence } from '_config/au/constants';
import { PO_BOX_TYPE, STREET_ADDRESS_TYPE, FIELDS } from 'address-lookup';
import { toLocalNumber } from 'utils/mobileNumber';
import { isFeatureOn } from 'featureToggles';
import { isAU } from '../../utils/byConfig';

const convertStringToIntPaths = [
  'personalDetails.dateOfBirth.day',
  'personalDetails.dateOfBirth.month',
  'personalDetails.dateOfBirth.year',
  'personalDetails.numberOfDependents',

  'otherDetails.lengthOfCurrentResidency.years',
  'otherDetails.lengthOfCurrentResidency.months',

  'extraDetails.expiryDate.day',
  'extraDetails.expiryDate.month',
  'extraDetails.expiryDate.year',

  'expensesDetails.facilityLimit',
  'expensesDetails.mortgageRentBoard.amount',
  'expensesDetails.foodGroceriesLifestyleEntertainment.amount',
  'expensesDetails.insurance.amount',
  'expensesDetails.utilities.amount',
  'expensesDetails.educationAndChildcare.amount',
  'expensesDetails.transport.amount',
  'expensesDetails.personalAndOtherExpense.amount',

  'employmentDetails.lengthOfTimeAtCurrentEmployer.years',
  'employmentDetails.lengthOfTimeAtCurrentEmployer.months',
  'employmentDetails.lengthOfUnemployment.years',
  'employmentDetails.lengthOfUnemployment.months',
  'employmentDetails.income.amount',
  'employmentDetails.otherIncome.amount',

  'additionalDebtsDetails.loanRepayment.amount',
  'additionalDebtsDetails.secondaryPropertyMortgageRentBoard.amount',
  'additionalDebtsDetails.totalCardLimits',
  'additionalDebtsDetails.amountOwingOnCreditCard',

  'achDetails.personalDetails.dateOfBirth.day',
  'achDetails.personalDetails.dateOfBirth.month',
  'achDetails.personalDetails.dateOfBirth.year',
];

const convertYesNoToBooleanPaths = [
  'otherDetails.isPostalSameWithResidential',
  'additionalDebtsDetails.hasOtherDebts',
  'expensesDetails.isExpensesShared',
  'expensesDetails.isSummaryExpenses',
  'additionalDebtsDetails.hasLoanLumpSumPayment',
  'additionalDebtsDetails.payLumpSumWithExistingSaving',
  'expensesDetails.isRevolvingOrOffsetMortgage',
  'employmentDetails.expectedIncomeChange',
  'employmentDetails.hasOtherIncome',
  'achDetails.hasAch',
  'achDetails.sameCurrentAddress',
];

const transformAddressPaths = [
  { address: 'otherDetails.currentAddress' },
  {
    condition: values =>
      values.otherDetails.lengthOfCurrentResidency.years < 3 &&
      values.otherDetails.hasNonDomesticPreviousAddress === false,
    address: 'otherDetails.previousAddress',
  },
  { condition: values => !values.otherDetails.isPostalSameWithResidential, address: 'otherDetails.postalAddress' },
  { condition: values => values.achDetails?.currentAddress, address: 'achDetails.currentAddress' },
];

const overwritePaths = [
  {
    when: transformedState => _get(transformedState, 'additionalDebtsDetails.hasOtherDebts') === false,
    changePaths: [
      'additionalDebtsDetails.loanRepayment.amount',
      'additionalDebtsDetails.secondaryPropertyMortgageRentBoard.amount',
      'additionalDebtsDetails.totalCardLimits',
      'additionalDebtsDetails.amountOwingOnCreditCard',
    ],
    value: 0,
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.hasOtherIncome') === false,
    changePaths: ['employmentDetails.otherEmploymentStatus', 'employmentDetails.otherIncome'],
    value: null,
  },
  {
    when: transformedState =>
      isFeatureOn('incomeReplay') && isEligibleForBankConnect(transformedState.employmentDetails.employmentStatus),
    changePaths: ['employmentDetails.income', 'employmentDetails.otherIncome'],
    value: null,
  },
  {
    when: (transformedState, storeState) => {
      const conditionMet =
        !isFeatureOn('creditLimit99999') &&
        _get(transformedState, 'creditLimitDetails.creditLimitSource') === OptionSystem;
      if (conditionMet) {
        // eslint-disable-next-line no-console
        console.error(
          `
Setting credit limit to null.
Application ID: ${storeState.applicationId}.
isFeatureOn('creditLimit99999'): ${isFeatureOn('creditLimit99999')}.
isFeatureOn('dynamicCreditLimit'): ${isFeatureOn('dynamicCreditLimit')}.
creditLimitSource: ${transformedState.creditLimitDetails.creditLimitSource}`,
        );
      }
      return conditionMet;
    },
    // deprecated behaviour. we use null to represent max credit limit requested by the user.
    // after features creditLimit99999 and dynamicCreditLimit released, we will use `99999` to represent max credit limit.
    changePaths: ['creditLimitDetails.creditLimit'],
    value: null,
  },
  {
    when: transformedState =>
      isFeatureOn('creditLimit99999') &&
      _get(transformedState, 'creditLimitDetails.creditLimitSource') === OptionSystem,
    changePaths: ['creditLimitDetails.creditLimit'],
    value: 99999,
  },
  {
    when: (transformedState, storeState) => {
      const conditionMet =
        isFeatureOn('creditLimit99999') &&
        isFeatureOn('dynamicCreditLimit') &&
        _get(transformedState, 'creditLimitDetails.creditLimitSource') === '';
      if (conditionMet) {
        // eslint-disable-next-line no-console
        console.error(
          `
Setting credit limit to null.
Application ID: ${storeState.applicationId}.
isFeatureOn('creditLimit99999'): ${isFeatureOn('creditLimit99999')}.
isFeatureOn('dynamicCreditLimit'): ${isFeatureOn('dynamicCreditLimit')}.
creditLimitSource: ${transformedState.creditLimitDetails.creditLimitSource}`,
        );
      }
      return conditionMet;
    },
    // user has not selected any credit limit options due to the new flow of dynamicCreditLimit
    changePaths: ['creditLimitDetails.creditLimit'],
    value: null,
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.otherEmploymentStatus') === '',
    changePaths: ['employmentDetails.otherEmploymentStatus'],
    value: null,
  },
  {
    when: transformedState => isSuperOrGov(_get(transformedState, 'employmentDetails.employmentStatus')),
    changePaths: ['employmentDetails.employerName'],
    value: 'WINZ/IRD',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === RENTAL_INCOME,
    changePaths: ['employmentDetails.employerName'],
    value: 'Rent',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === INVESTMENT,
    changePaths: ['employmentDetails.employerName'],
    value: 'Investment',
  },
  {
    when: transformedState => isRentalOrInvestment(_get(transformedState, 'employmentDetails.employmentStatus')),
    changePaths: ['employmentDetails.industry'],
    value: 'PROPERTY_BUSINESS',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === GOVERNMENT_BENEFIT,
    changePaths: ['employmentDetails.industry'],
    value: 'UNEMPLOYED',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === UNEMPLOYED,
    changePaths: ['employmentDetails.industry'],
    value: 'UNEMPLOYED',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === UNEMPLOYED,
    changePaths: ['employmentDetails.employerName'],
    value: null,
  },
];

const removePaths = [
  'overview',
  'verification',
  'emailVerification',
  'biometricsAboutYou',
  'biometricsAddressDetails',
  'personalDetails',
  'preferredCreditLimitDetails',
  'acceptance',
  'confirmCreditLimit',
  'confirmReferredCreditLimit',
  'expensesDetails',
  'employmentDetails',
  'employmentDetailsV2.hasOtherIncome',
  'employmentDetailsV2.incomeAdjustmentRequired',
  'employmentDetailsV2.conditionalStop',
  'additionalDebtsDetails.secondaryPropertyMortgageRentBoard',
  'achDetails.personalDetails.hasMiddleName',
];

const toStreetAddress = address => {
  const streetAddressFields = FIELDS[config.countryCode].STREET_ADDRESS_FIELDS;
  return Object.keys(address).reduce(
    (acc, key) =>
      streetAddressFields.includes(key) ? { ...acc, ...(address[key] ? { [key]: address[key] } : null) } : acc,
    {},
  );
};

const toPoBoxAddress = address => {
  const poBoxFields = FIELDS[config.countryCode].PO_BOX_FIELDS;
  return Object.keys(address).reduce(
    (acc, key) => (poBoxFields.includes(key) ? { ...acc, ...(address[key] ? { [key]: address[key] } : null) } : acc),
    {},
  );
};

// This function should only be used in AU.
export const toSaveAndSubmitApplicationInput = (formData, storeState) => {
  if (!isAU()) {
    throw new Error('saveAndSubmitApplication mutation should only be used in AU');
  }

  let transformed = _cloneDeep(formData);

  Object.assign(transformed, convertConditionalFields(transformed, storeState));

  transformed.dvsConsent = formData.personalDetails.dvsConsent;
  transformed.crbConsent = formData.personalDetails.crbConsent;

  transformed.contactDetails.mobileNumber = toLocalNumber(transformed.contactDetails.mobileNumber);

  transformed.password = transformed.password.password || null;

  transformed.contactDetails = {
    ...transformed.contactDetails,
    ..._pick(transformed.personalDetails, ['title', 'firstName', 'middleName', 'lastName', 'dateOfBirth']),
  };

  _.uniq(convertStringToIntPaths).forEach(path => {
    if (!_hasIn(transformed, path)) {
      return;
    }
    const value = _get(transformed, path, '0');
    const newValue = typeof value === 'string' ? parseInt(value, 10) : 0;
    _set(transformed, path, Number.isNaN(newValue) ? 0 : newValue);
  });

  transformed = convertFrequencyToUpperCase(transformed);

  _.uniq(convertYesNoToBooleanPaths).forEach(path => {
    const value = _get(transformed, path);
    const newValue = value === 'yes';
    _set(transformed, path, newValue);
  });
  _.uniq(transformAddressPaths).forEach(path => {
    if (!path.condition || path.condition(transformed)) {
      const { address } = _get(transformed, path.address);
      // TODO remove hardcoding of addressType and countryCode - https://di.latitudefinancial.com/jira/browse/NZP-524
      const validAddress =
        address.addressType === PO_BOX_TYPE
          ? { addressType: PO_BOX_TYPE, poBoxAddress: { ...toPoBoxAddress(address), countryCode: config.countryCode } }
          : {
              addressType: STREET_ADDRESS_TYPE,
              streetAddress: {
                ...toStreetAddress(address),
                addressType: STREET_ADDRESS_TYPE,
                countryCode: config.countryCode,
              },
            };
      _set(transformed, path.address, validAddress);
    } else {
      _set(transformed, path.address, null);
    }
  });
  // type OverwritePaths = Array<{
  //   when: (s: transformedState) => boolean,
  //   changePaths: string[],
  //   value: unknown,
  // }>
  _.uniq(overwritePaths).forEach(({ when, changePaths, value }) => {
    const isTrue = when(transformed, storeState);
    if (isTrue) {
      changePaths.forEach(path => _set(transformed, path, value));
    }
  });

  const noDriverLicence = formData.personalDetails.hasAuDL === 'no';

  transformed.extraDetails = {
    relationshipStatus: transformed.personalDetails.relationshipStatus,
    numberOfDependents: transformed.personalDetails.numberOfDependents,
    idType: noDriverLicence ? null : idTypeAUDriverLicence,
    idAUDriversLicence: noDriverLicence
      ? null
      : {
          licenceNumber: transformed.personalDetails.driverLicenceNumber,
          cardNumber: transformed.personalDetails.driverLicenceCardNumber,
          australianState: transformed.personalDetails.australianState,
        },
  };

  transformed.employmentDetailsV2 = transformed.employmentDetails;

  transformed.expensesInformation = {
    isPayAllExpenses: !transformed.expensesDetails.isExpensesShared,
    // TODO deprecate and remove redundant field `isRevolvingOrOffsetMortgage` for both AU and NZ. Activate only cares about `facilityLimit`. Card: https://di.latitudefinancial.com/jira/browse/OBS-4486
    isRevolvingOrOffsetMortgage: false,
  };

  if (transformed.expensesDetails?.isSummaryExpenses) {
    transformed.nonItemisedExpensesDetails = {
      mortgageRentBoard: transformed.expensesDetails.mortgageRentBoard,
      secondaryPropertyMortgageRentBoard: transformed.additionalDebtsDetails.secondaryPropertyMortgageRentBoard, // not enabled in AU
      basicExpensesSummary: transformed.expensesDetails.basicExpenses,
      additionalExpensesSummary: transformed.expensesDetails.additionalExpenses,
      useExpenseSummary: transformed.expensesDetails.isSummaryExpenses,
    };
  } else {
    transformed.nonItemisedExpensesDetails = {
      useExpenseSummary: transformed.expensesDetails.isSummaryExpenses,
      mortgageRentBoard: transformed.expensesDetails.mortgageRentBoard,
      secondaryPropertyMortgageRentBoard: transformed.additionalDebtsDetails.secondaryPropertyMortgageRentBoard, // not enabled in AU
      foodGroceriesLifestyleEntertainment: transformed.expensesDetails.foodGroceriesLifestyleEntertainment,
      insurance: transformed.expensesDetails.insurance,
      utilities: transformed.expensesDetails.utilities,
      educationAndChildcare: transformed.expensesDetails.educationAndChildcare,
      transport: transformed.expensesDetails.transport,
      personalAndOtherExpense: transformed.expensesDetails.personalAndOtherExpense,
    };
  }
  // TODO deprecate and remove redundant field `homeLoans` for both AU and NZ. Activate only cares about `secondaryPropertyMortgageRentBoard`. Card: https://di.latitudefinancial.com/jira/browse/OBS-4486
  transformed.additionalDebtsDetails.homeLoans = 0;
  if (isFeatureOn('dynamicCreditLimit')) {
    transformed.creditLimitDetails = {};
  } else {
    transformed.creditLimitDetails = { creditLimit: 0 };
  }

  _.uniq(removePaths).forEach(path => _unset(transformed, path));

  transformed.applyAndBuyDetails = {
    merchantId: storeState.applyAndBuy.merchantId,
    shortCode: storeState.applyAndBuy.shortCode,
    solCode: storeState.applyAndBuy.solCode,
    merchantSourceCode: storeState.applyAndBuy.merchantSourceCode,
  };

  transformed.achDetails = convertAchDetails(transformed);

  Object.assign(transformed, trimStringFields(transformed));

  return transformed;
};

const convertAchDetails = data => {
  if (!isFeatureOn('ach') || !data.achDetails?.hasAch) {
    return null;
  }

  const achDetails = _cloneDeep(data.achDetails?.personalDetails);
  if (data.achDetails?.currentAddress === null) {
    achDetails.currentAddress = _.cloneDeep(data.otherDetails.currentAddress);
  } else {
    achDetails.currentAddress = _cloneDeep(data.achDetails?.currentAddress);
  }

  return achDetails;
};

/**
 * Fill conditional fields with default values when their conditions are not met
 */
const convertConditionalFields = (formState, storeState) => {
  return _reduce(
    formState,
    (acc, sectionValue, sectionName) => {
      return {
        ...acc,
        [sectionName]: convertConditionalFieldsInOneSection(sectionValue, sectionName, storeState),
      };
    },
    {},
  );
};

const convertConditionalFieldsInOneSection = (fields, parentPath, storeState) => {
  const conditionalFieldsWithDefaultValues = _reduce(
    fields,
    (acc, _fieldValue, fieldName) => {
      const fieldConfig = findFieldConfig(fieldName, config.formConfigs[parentPath]);

      if (!fieldConfig?.condition || fieldConfig?.condition(fields, storeState) === true) {
        return acc;
      }

      if (!fieldConfig.fields) {
        const defaultValue = _get(config.initialFormValues, `${parentPath}.${fieldName}`);
        return {
          ...acc,
          [fieldName]: defaultValue,
        };
      }

      const dependentFieldNames = fieldConfig.fields.map(field => field.name);
      const dependentFieldDefaultValues = _pick(config.initialFormValues[parentPath], dependentFieldNames);
      return {
        ...acc,
        ...dependentFieldDefaultValues,
      };
    },
    {},
  );

  return Object.assign({}, fields, conditionalFieldsWithDefaultValues);
};

const trimStringFields = fields => {
  return _reduce(
    fields,
    (acc, fieldValue, fieldName) => {
      if (fieldValue === null || fieldValue === undefined) {
        return { ...acc, [fieldName]: fieldValue };
      }
      switch (typeof fieldValue) {
        case 'string':
          return {
            ...acc,
            [fieldName]: fieldValue.trim(),
          };
        case 'object':
          return {
            ...acc,
            [fieldName]: trimStringFields(fieldValue),
          };
        default:
          return {
            ...acc,
            [fieldName]: fieldValue,
          };
      }
    },
    {},
  );
};

const findFieldConfig = (fieldName, fieldConfigs) => {
  let fieldConfigsCopy = fieldConfigs;
  if (!fieldConfigsCopy) {
    return null;
  }

  if (typeof fieldConfigs === 'function') {
    fieldConfigsCopy = fieldConfigs();
  }

  return fieldConfigsCopy.find(fieldConfig => {
    if (fieldConfig.name === fieldName) {
      return true;
    }
    if (fieldConfig.fields) {
      return findFieldConfig(fieldName, fieldConfig.fields);
    }
    return false;
  });
};

const convertFrequencyToUpperCase = (fields, parentPath) => {
  return _reduce(
    fields,
    (acc, fieldValue, fieldName) => {
      if (fieldValue === null || fieldValue === undefined) {
        return { ...acc, [fieldName]: fieldValue };
      }
      if (
        fieldName === 'frequency' &&
        ['annually', 'quarterly', 'monthly', 'fortnightly', 'weekly'].includes(fieldValue)
      ) {
        return {
          ...acc,
          [fieldName]: fieldValue.toUpperCase(),
        };
      }
      if (typeof fieldValue === 'object') {
        return {
          ...acc,
          [fieldName]: convertFrequencyToUpperCase(fieldValue, `${parentPath}.${fieldName}`),
        };
      }
      return {
        ...acc,
        [fieldName]: fieldValue,
      };
    },
    {},
  );
};
