/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { Container, Link, Button, Grid } from 'basis';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import {
  useStoreValue,
  OKTA_VERIFICATION_LOCKED_ERROR,
  OKTA_VERIFICATION_ERROR,
  OKTA_EMPTY_MOBILE_NUMBER_ERROR,
  OKTA_SMS_FACTOR,
  OKTA_EMAIL_FACTOR,
} from 'store';
import { LayoutPage, LayoutContent, Form, Errors, SecureIcon, OneTimeCode, Text } from 'components';
import { byCountry, getDataLayerElements, submitHandler, isAU } from 'utils';
import {
  useErrorTrackingAndUpdateStore,
  useErrorTracking,
  useFormInitialValues,
  useOktaClient,
  useSteps,
  useResume,
  useSendWelcomeEmail,
  useSaveDraft,
  useThreatMetrix,
} from 'hooks';
import { DEV_FEATURES } from '__dev/devFeatures';
import { RESEND_MIN } from '_config/_constants/verificationPage';
import { STEP_PASSWORD, STEP_ABOUT_YOU, STEP_LATITUDE_ID, STEP_VERIFY_IDENTITY, STEP_PROCESSING } from '_config';
import { OktaProfileError } from 'components/errors/OktaProfileError';
import styled from '@emotion/styled';
import { isFeatureOn } from 'featureToggles';
import { GuideCodeNotReceived } from 'components/errors/GuideCodeNotReceived';
import { useFocusOnFirstFormElement } from '../../hooks/useFocusOnFirstFormElement';

const FORM_ID = 'verification';
const EMAIL_VERIFICATION_FORM_ID = 'emailVerification';

export function Verification() {
  const [storeState, updateStore] = useStoreValue();
  const factor = storeState.oktaFactor || OKTA_SMS_FACTOR;

  useThreatMetrix({ disabled: factor === OKTA_EMAIL_FACTOR && storeState.oktaProfileHealthy });
  const { pathname } = useSteps();
  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const { handleErrorTracking } = useErrorTracking();
  const { resume, loading: resuming } = useResume({ applicationId: storeState.applicationId, updateStore });
  const initialValues = useFormInitialValues(factor === OKTA_SMS_FACTOR ? FORM_ID : EMAIL_VERIFICATION_FORM_ID);
  const { sendEmail: sendWelcomeEmail } = useSendWelcomeEmail();
  const { saveDraft, savingDraft } = useSaveDraft();

  useFocusOnFirstFormElement();

  const noProgressStepper = isAU() && factor === OKTA_SMS_FACTOR;

  const onStartAgainClick = label => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: pathname.slice(1),
        label,
      },
    });

    updateStore({
      isResuming: false,
      verificationLocked: true,
      applicationErrors: null,
    });

    history.push('/');
  };

  const onResendCodeClick = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: pathname.slice(1),
        label: 'Resend code',
      },
    });
  };

  const handleVerifySmsOtpSuccess = useCallback(
    async ({ isLatitudeIdPasswordSet, isEmailVerified }) => {
      updateStore({
        isLatitudeIdPasswordSet,
        isEmailVerified,
      });

      if (storeState.isResuming) {
        await resume({ storeState: { ...storeState, isLatitudeIdPasswordSet, isEmailVerified } });
      } else {
        const nextStep = getNextStep({
          isLatitudeIdPasswordSet,
          factor,
          oktaProfileHealthy: storeState.oktaProfileHealthy,
        });

        if (isFeatureOn('saveAndResume')) {
          if (isAU()) {
            sendWelcomeEmail();
          }
          if (!savingDraft) {
            saveDraft({
              ...storeState,
              activeStep: nextStep,
            });
          }
        }

        updateStore({
          activeStep: nextStep,
          applicationErrors: null,
        });
        history.push(nextStep);
      }
    },
    [factor, history, resume, saveDraft, savingDraft, sendWelcomeEmail, storeState, updateStore],
  );

  const handleVerifyEmailOtpSuccess = useCallback(
    async ({ isLatitudeIdPasswordSet }) => {
      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: 'verify-email',
          label: 'Verify',
        },
        ...getDataLayerElements(storeState),
      });

      const nextStep = getNextStep({
        isEmailVerified: true,
        isLatitudeIdPasswordSet,
        factor,
        oktaProfileHealthy: storeState.oktaProfileHealthy,
      });

      if (!storeState.oktaProfileHealthy) {
        if (isFeatureOn('saveAndResume')) {
          if (isAU()) {
            sendWelcomeEmail();
          }
          if (!savingDraft) {
            saveDraft({
              ...storeState,
              activeStep: nextStep,
            });
          }
        }
      }

      updateStore({
        isEmailVerified: true,
        activeStep: nextStep,
        applicationErrors: null,
      });
      history.push(nextStep);
    },
    [factor, history, saveDraft, savingDraft, sendWelcomeEmail, storeState, trackEvent, updateStore],
  );

  const handleSuccess = useCallback(
    async ({ isLatitudeIdPasswordSet, isEmailVerified }) => {
      switch (factor) {
        case OKTA_EMAIL_FACTOR:
          return handleVerifyEmailOtpSuccess({ isLatitudeIdPasswordSet });
        case OKTA_SMS_FACTOR:
          return handleVerifySmsOtpSuccess({ isLatitudeIdPasswordSet, isEmailVerified });
        default:
          throw new Error(`Unknown factor: ${factor}`);
      }
    },
    [factor, handleVerifyEmailOtpSuccess, handleVerifySmsOtpSuccess],
  );

  const handleVerifyOTPError = error => {
    if (error.type === OKTA_VERIFICATION_LOCKED_ERROR) {
      handleErrorTracking(error);
      updateStore({
        verificationLocked: true,
      });
    } else {
      handleErrorCallback(error);
    }
  };

  const { loading: loadingOkta, verifyOTP, applySyntheticToken } = useOktaClient({
    onSuccess: handleSuccess,
    onError: handleVerifyOTPError,
  });

  const doSubmit = useCallback(
    async (verification, setErrors) => {
      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: storeState.isResuming ? 'verify-to-resume' : 'verify',
          label: 'Verify',
        },
        ...getDataLayerElements(storeState),
      });

      if (DEV_FEATURES.OTP_SKIP) {
        const success = await applySyntheticToken();
        const isRunningSyntheticTest = !!process.env.REACT_APP_OKTA_TOKEN;
        success &&
          handleSuccess({
            isLatitudeIdPasswordSet: isRunningSyntheticTest,
            ...(factor === OKTA_EMAIL_FACTOR ? { isEmailVerified: true } : null),
          });
      } else {
        const { errorMessage } = await verifyOTP(
          verification.verificationCode,
          {
            checkIsLatitudeIdPasswordSet: true,
          },
          factor,
        );
        if (errorMessage && setErrors) {
          return setErrors({ verificationCode: errorMessage });
        }
      }
      return null;
    },
    [applySyntheticToken, factor, handleSuccess, storeState, trackEvent, verifyOTP],
  );

  const onSubmit = ({ values, errors, setErrors }) => {
    async function verifyOTPAndRedirect() {
      doSubmit(values, setErrors);
    }

    submitHandler({ submit: verifyOTPAndRedirect, errors });
  };

  const retrySubmit = useCallback(async () => {
    doSubmit(storeState.acquisition.verification);
  }, [doSubmit, storeState.acquisition.verification]);

  if (isAU() && storeState.applicationErrors?.type === OKTA_VERIFICATION_ERROR) {
    // i.e. AU customer lost network
    return (
      <LayoutPage noProgressStepper={noProgressStepper}>
        <Errors applicationRef={storeState.applicationRef} retry={retrySubmit} retrying={loadingOkta} isPreSubmission />
      </LayoutPage>
    );
  }

  if (storeState.verificationLocked) {
    return (
      <LayoutPage noProgressStepper={noProgressStepper} headerOverride="Unable to verify your identity">
        <Container margin="0 0 13 0" hasBreakpointWidth width="100%">
          <LayoutContent rowsGap="0">
            <Container>
              <Text>
                <strong>You have exceeded the maximum attempts allowed.</strong>
              </Text>
            </Container>
            <Container>
              <Text>As a result, we're unable to process your request at this time.</Text>
            </Container>
            <Container padding="8 0 0 0">
              <Link
                appearance="primary-button"
                variant="blue-button"
                href="/"
                newTab={false}
                onClick={() => onStartAgainClick('Restart application')}
              >
                Restart application
              </Link>
            </Container>
          </LayoutContent>
        </Container>
      </LayoutPage>
    );
  }

  const hasOktaProfileBlankMobileNumberError = storeState.applicationErrors?.type === OKTA_EMPTY_MOBILE_NUMBER_ERROR;

  return (
    <LayoutPage noProgressStepper={noProgressStepper} hideTitleIfErrors={false}>
      <Form
        id={factor === OKTA_SMS_FACTOR ? FORM_ID : EMAIL_VERIFICATION_FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={loadingOkta || resuming}
        submitButtonLabel="Verify"
        disableFormButtons={hasOktaProfileBlankMobileNumberError}
        disableFormCache
        hideFormButtons={byCountry({
          AU: true,
          NZ: false,
        })}
      >
        {formData => {
          return (
            <LayoutContent>
              <OneTimeCode
                name="verificationCode"
                label="Verification code"
                testId="verification-code"
                factorTarget={
                  factor === OKTA_SMS_FACTOR
                    ? storeState.maskedMobileNumber
                    : storeState.acquisition.contactDetails.emailAddress
                }
                codeLength="6"
                resendMsecs={RESEND_MIN * 60000}
                onResendCodeClick={onResendCodeClick}
                onResendCodeError={onResendCodeClick}
                factor={factor}
                formData={formData}
              />
              {isAU() ? <VerifyButton loading={loadingOkta} /> : null}
              {hasOktaProfileBlankMobileNumberError && <OktaProfileError />}
              <GuideCodeNotReceived contactType={factor === OKTA_SMS_FACTOR ? 'sms' : 'email'} />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}

const VerifyButton = ({ loading }) => (
  <Grid colsGap="10" cols="2">
    <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
      <Grid>
        <Button testId="verify-btn" type="submit" loading={loading}>
          <IconAndText>
            <SecureIcon color="white" />
            Verify
          </IconAndText>
        </Button>
      </Grid>
    </Grid.Item>
  </Grid>
);

const IconAndText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
    margin-top: -2px;
  }
`;

function getNextStep({ isLatitudeIdPasswordSet, factor, oktaProfileHealthy }) {
  // ignore resume cases as they are handled in useResume hook.

  if (factor === OKTA_EMAIL_FACTOR && oktaProfileHealthy) {
    // post-submission email verification done by a healthy okta profile
    return isLatitudeIdPasswordSet ? STEP_PROCESSING : STEP_LATITUDE_ID;
  }

  // Either SMS OTP done by a healthy okta profile; or
  // a fallback email OTP done by an unhealthy okta profile
  return byCountry(
    {
      AU: () => {
        return !isLatitudeIdPasswordSet && !isFeatureOn('emailVerification') ? STEP_LATITUDE_ID : STEP_ABOUT_YOU;
      },
      NZ: () => {
        if (isFeatureOn('biometrics') && isLatitudeIdPasswordSet) {
          return STEP_VERIFY_IDENTITY;
        }
        if (isFeatureOn('biometrics') && !isLatitudeIdPasswordSet) {
          return STEP_LATITUDE_ID;
        }
        return STEP_PASSWORD;
      },
    },
    { lazy: true },
  );
}
